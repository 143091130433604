'use client';
import { Vortex } from '@/components/ui/vortex';
import { useTheme } from 'next-themes';
import { useEffect } from 'react';

export function Hero({ dict }) {
  const { setTheme } = useTheme();
  useEffect(() => {
    setTheme("dark")
  }, []);
  return (
    <>
      <div className="w-full mx-auto rounded-md overflow-hidden h-screen">
        <Vortex
          backgroundColor="black"
          className="flex items-center flex-col justify-center px-2 md:px-10 py-4 w-full h-full"
        >
          <h2 className="text-white text-2xl md:text-6xl font-bold text-center">
            {dict.title}
          </h2>
          <p className="text-white text-xl md:text-3xl max-w-xl mt-6 text-center">
            {dict.subTitle}
          </p>
          <br />
          {/* <Highlight className="text-white text-xl md:text-6xl font-bold text-center"><h3>Let's start this journey!</h3></Highlight> */}
        </Vortex>
      </div>
    </>
  );
}
