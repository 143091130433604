'use client';
import { TypewriterEffectSmooth } from '@/components/ui/typewriter-effect';
export function Contact({
  dict
}) {
  const words = dict.words;
  return (
    <div
      className="flex flex-col items-center justify-center h-[40rem]"
      id="contact"
    >
      <p className="text-white text-xs sm:text-base  ">
        {dict.title}
      </p>
      <TypewriterEffectSmooth words={words} />
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-0 md:space-x-4">
        <a
          href={`https://api.whatsapp.com/send?phone=${process.env.NEXT_PUBLIC_PHONE_CONTACT}`}
          target="_blank"
          className="flex justify-end items-center"
        >
          <button className="w-40 h-10 rounded-xl bg-white border dark:border-white border-transparent text-black text-sm font-bold">
            {dict.button}
          </button>
        </a>
      </div>
    </div>
  );
}
