'use client';

export function Explain({ dict }) {
  const dummyContent = dict.map((res) => {
    return {
      title: res.title,
      description: <>{res.description}</>,
      badge: res.badge,
      image: res.image,
      points: res.points,
    };
  });

  return (
    <div className="w-full z-50" id="journey">
      {dummyContent.map((res, index) => {
        const positionFlexContent =
          (index + 1) % 2 === 0 ? 'flex-col lg:flex-row-reverse' : 'flex-col lg:flex-row';
          const positionFlexContainer =
            (index + 1) % 2 === 0 ? 'flex items-center' : '';
        const lastItemBackGround =
          index + 1 === dummyContent.length ? 'flex custom-gradient' : '';

        if (typeof res.points !== 'undefined') {
          return <SectionItems content={res} />;
        }
        return (
          <SectionImageText
            key={`SectionImageText__${index}`}
            content={res}
            index={index}
            positionFlexContainer={positionFlexContainer}
            positionFlexContent={positionFlexContent}
            lastItemBackGround={lastItemBackGround}
          />
        );
      })}
    </div>
  );
}

export const SectionImageText = ({
  content,
  index,
  positionFlexContainer,
  positionFlexContent,
  lastItemBackGround,
}): any => {
  return (
    <section
      className={`py-14 sm:py-0 sm:min-h-screen w-full ${lastItemBackGround}`}
      key={`explain-${index}`}
    >
      <div className={`container space-y-10 xl:space-y-16 px-4 md:px-6 ${positionFlexContainer}`}>
        <div
          className={`flex ${positionFlexContent} justify-between items-center w-full gap-16`}
        >
          <div className="flex flex-col lg:w-5/12 gap-4">
            <h1 className="lg:leading-tighter text-3xl font-bold tracking-tighter sm:text-4xl xl:text-[3.4rem] 2xl:text-[3.75rem]">
              {content.title}
            </h1>
            <p className="mx-auto max-w-[700px] text-gray-500 md:text-lg dark:text-gray-400">
              {content.description}
            </p>
          </div>
          <div className="flex justify-center lg:w-7/12">
            <img
              src={content.image}
              alt="Hero"
              className="mx-auto aspect-auto overflow-hidden rounded-xl object-contain"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionItems = ({ content }) => {
  return (
    <section className={`py-14 sm:py-0 min-h-screen w-full`}>
      <div className="container space-y-10 xl:space-y-16 px-4 md:px-6">
        <div className="flex flex-col items-center lg:items-stretch justify-center space-y-4 text-center">
          <div className="space-y-2">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl xl:text-[3.4rem] 2xl:text-[3.75rem]">
              {content.title}
            </h2>
          </div>
        </div>
        <div className="flex flex-col items-center lg:items-stretch justify-between h-full w-full lg:flex-row gap-12">
          <div className="flex flex-1 flex-col gap-12 w-full sm:w-8/12 lg:w-3/12 lg:gap-0 justify-between">
            <div className="flex flex-col my-auto gap-4">
              <div className="flex items-center flex-col lg:flex-row-reverse gap-4">
                <div className="rounded-full bg-gray-100 p-3 dark:bg-gray-800">
                  <GlobeIcon className="h-6 w-6" />
                </div>
                <h3 className="text-lg font-bold">{content.points[0].title}</h3>
              </div>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {content.points[0].description}
              </p>
            </div>
            <div className="flex flex-col my-auto gap-4">
              <div className="flex items-center flex-col lg:flex-row-reverse gap-4">
                <div className="rounded-full bg-gray-100 p-3 dark:bg-gray-800">
                  <SmartphoneIcon className="h-6 w-6" />
                </div>
                <h3 className="text-lg font-bold">{content.points[1].title}</h3>
              </div>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {content.points[1].description}
              </p>
            </div>
          </div>
          <div className="flex flex-col-reverse lg:flex-row  lg:w-9/12 gap-12 items-center lg:items-stretch">
            <div className="flex justify-center lg:w-8/12">
              <img
                src={content.image}
                alt="Hero"
                className="mx-auto aspect-auto overflow-hidden rounded-xl object-contain "
              />
            </div>

            <div className="flex flex-1 flex-col gap-12 w-full sm:w-8/12 lg:w-2/12 lg:gap-0 justify-between">
              <div className="flex flex-col my-auto gap-4">
                <div className="flex items-center flex-col lg:flex-row gap-4">
                  <div className="rounded-full bg-gray-100 p-3 dark:bg-gray-800">
                    <ShoppingBagIcon className="h-6 w-6" />
                  </div>
                  <h3 className="text-lg font-bold">{content.points[2].title}</h3>
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {content.points[2].description}
                </p>
              </div>
              <div className="flex flex-col my-auto gap-4">
                <div className="flex items-center flex-col lg:flex-row gap-4">
                  <div className="rounded-full bg-gray-100 p-3 dark:bg-gray-800">
                    <CodeIcon className="h-6 w-6" />
                  </div>
                  <h3 className="text-lg font-bold">{content.points[3].title}</h3>
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {content.points[3].description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

function GlobeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20" />
      <path d="M2 12h20" />
    </svg>
  );
}
function SmartphoneIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="14" height="20" x="5" y="2" rx="2" ry="2" />
      <path d="M12 18h.01" />
    </svg>
  );
}
function ShoppingBagIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4Z" />
      <path d="M3 6h18" />
      <path d="M16 10a4 4 0 0 1-8 0" />
    </svg>
  );
}

function CodeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="16 18 22 12 16 6" />
      <polyline points="8 6 2 12 8 18" />
    </svg>
  );
}
