'use client';

import { cn } from '@/utils/cn';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { useRef, useState } from 'react';

export default function Portifolio({ dict }) {
  return (
    <section id="portifolio" className={`flex py-14 sm:py-0 min-h-[90vh] w-full`}>
      <div className="flex flex-col  container my-10 xl:my-16 px-4 md:px-6 min-h-full">
        <div className="flex flex-col items-center lg:items-stretch justify-center my-4 text-center">
          <div className="my-2">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl xl:text-[3.4rem] 2xl:text-[3.75rem]">
              {dict.title}
            </h2>
          </div>
        </div>
        <div className='flex flex-col sm:flex-row overflow-x-auto gap-4 h-full items-center'>
          <a target="_blank" href={'https://play.google.com/store/apps/details?id=com.contudoup.ligabfs'}>
            <DirectionAwareHover imageUrl={'/images/ligabfs.webp'} children={'LigaBFS'}/>
          </a>
          <a target="_blank" href={'https://zappsite.com/'}>
            <DirectionAwareHover imageUrl={'/images/zapsites.png'} children={'Zappsite'}/>
          </a>
        </div>
      </div>
    </section>
  );
}

const DirectionAwareHover = ({
  imageUrl,
  children,
  childrenClassName,
  imageClassName,
  className,
}: {
  imageUrl: string;
  children: React.ReactNode | string;
  childrenClassName?: string;
  imageClassName?: string;
  className?: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [direction, setDirection] = useState<
    'top' | 'bottom' | 'left' | 'right' | string
  >('left');

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (!ref.current) return;

    const direction = getDirection(event, ref.current);
    console.log('direction', direction);
    switch (direction) {
      case 0:
        setDirection('top');
        break;
      case 1:
        setDirection('right');
        break;
      case 2:
        setDirection('bottom');
        break;
      case 3:
        setDirection('left');
        break;
      default:
        setDirection('left');
        break;
    }
  };

  const getDirection = (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent>,
    obj: HTMLElement,
  ) => {
    const { width: w, height: h, left, top } = obj.getBoundingClientRect();
    const x = ev.clientX - left - (w / 2) * (w > h ? h / w : 1);
    const y = ev.clientY - top - (h / 2) * (h > w ? w / h : 1);
    const d = Math.round(Math.atan2(y, x) / 1.57079633 + 5) % 4;
    return d;
  };

  return (
    <motion.div
      onMouseEnter={handleMouseEnter}
      ref={ref}
      className={cn(
        'w-full h-64 md:h-96 sm:w-60 sm:h-60 md:w-96 bg-transparent rounded-lg overflow-hidden group/card relative',
        className,
      )}
    >
      <AnimatePresence mode="wait">
        <motion.div
          className="relative h-full w-full"
          initial="initial"
          whileHover={direction}
          exit="exit"
        >
          <motion.div className="group-hover/card:block hidden absolute inset-0 w-full h-full bg-black/40 z-10 transition duration-500" />
          <motion.div
            variants={variants}
            className="h-full w-full relative bg-gray-50 dark:bg-black"
            transition={{
              duration: 0.2,
              ease: 'easeOut',
            }}
          >
            <Image
              alt="image"
              className={cn(
                'h-full w-full object-cover scale-[1.15]',
                imageClassName,
              )}
              width="1000"
              height="1000"
              src={imageUrl}
            />
          </motion.div>
          <motion.div
            variants={textVariants}
            transition={{
              duration: 0.5,
              ease: 'easeOut',
            }}
            className={cn(
              'text-white absolute bottom-4 left-4 z-40 text-2xl',
              childrenClassName,
            )}
          >
            {children}
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
};

const variants = {
  initial: {
    x: 0,
  },

  exit: {
    x: 0,
    y: 0,
  },
  top: {
    y: 20,
  },
  bottom: {
    y: -20,
  },
  left: {
    x: 20,
  },
  right: {
    x: -20,
  },
};

const textVariants = {
  initial: {
    y: 0,
    x: 0,
    opacity: 0,
  },
  exit: {
    y: 0,
    x: 0,
    opacity: 0,
  },
  top: {
    y: -20,
    opacity: 1,
  },
  bottom: {
    y: 2,
    opacity: 1,
  },
  left: {
    x: -2,
    opacity: 1,
  },
  right: {
    x: 20,
    opacity: 1,
  },
};
